<template>
  <div class="about">
    <div class="band fade-in"></div>
    <h1 class="slide-in-left" v-html="$t('aboutFirstParagraph')"></h1>
    <p class="slide-in-right">{{ $t('aboutSecondParagraph') }}</p>
    <p class="slide-in-left">{{ $t('aboutThirdParagraph') }}</p>
    <p class="slide-in-right">{{ $t('aboutFourthParagraph') }}</p>
    <p class="slide-in-left">{{ $t('aboutFifthParagraph') }}</p>
    <p class="slide-in-right" v-html="$t('aboutSixthParagraph')"></p>
    <div class="band fade-in"></div>
    <p class="tip fade-in">{{ isMobile ? $t('aboutQuickTipMobile') : $t('aboutQuickTip') }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      this.isMobile = window.innerWidth <= 768; 
    },
  },
};
</script>

<style scoped>
.about {
  text-align: center;
}

h1 {
  text-align: justify; 
  margin-left: 20%;
  margin-right: 20%;
  font: 300 20px 'Inconsolata', serif;
  animation: slideInLeft 1s forwards;
}

p {
  text-align: justify; 
  margin-left: 20%;
  margin-right: 20%;
  font: 300 17px 'Inconsolata', serif;
  opacity: 0;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.band {
  height: 0.75vh;
  background-color: #246A73; 
  width: 60%;
  display: inline-block;
  margin: 3vh 0;
  opacity: 0;
  animation: fadeIn 1s forwards;
}

.tip {
  font: 500 14px 'Inconsolata', serif;
  color: #9c9c9c;
  text-align: center;
  opacity: 0;
  animation: fadeIn 1s forwards;
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.slide-in-left {
  animation-name: slideInLeft;
}

.slide-in-right {
  animation-name: slideInRight;
}

h1, .slide-in-left {
  animation: slideInLeft 1s forwards;
}

.slide-in-right {
  animation: slideInRight 1s forwards;
}

p:nth-of-type(2) {
  animation-delay: 0.2s;
}

p:nth-of-type(3) {
  animation-delay: 0.4s;
}

p:nth-of-type(4) {
  animation-delay: 0.6s;
}

p:nth-of-type(5) {
  animation-delay: 0.8s;
}

p:nth-of-type(6) {
  animation-delay: 1s;
}

.fade-in {
  animation-name: fadeIn;
}

@media (max-width: 991px) {
  h1, p {
    margin: 0 10%;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .tip {
    font-size: 12px;
  }

  .band {
    width: 97%;
    margin-top: 0.5vh;
  }
}

@media (max-width: 768px) {
  h1, p {
    margin: 0 5%;
    font-size: 16px;
    margin-bottom: 15px;
  }

  .tip {
    font-size: 12px;
  }

  .band {
    width: 97%;
    margin-top: 0.5vh;
  }
}

@media (max-width: 576px) {
  h1, p {
    margin: 0 2%;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .tip {
    font-size: 12px;
  }

  .band {
    width: 97%;
    margin-top: 0.5vh;
  }
}
</style>
